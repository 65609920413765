* {
  font-family: "Inter", sans-serif !important;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}

/* COLORS */
:root {
  --p50: #f1f8ff;
  --p50: #ccefff;
  --p100: #cde9fb;
  --p200: #9dcff8;
  --p300: #6aaceb;
  --p400: #4489d8;
  --p500: #115abe;
  --p600: #0c45a3;
  --p700: #083388;
  --p800: #05246e;
  --p900: #03195b;

  --p500a: #1662cd0c;

  --a: #4f3492;

  --ba: #050505a6;
  --bt: #333333;
  --wt: #eeeeee;

  --divider: #b4b4b420;
  --divider2: #b4b4b440;
  --divider3: #b4b4b450;
  --divider4: #b4b4b47b;

  --dark: #1f2937;

  --red: #e53e3e;
  --yellow: #cece1b;
  --blue: #115abe;
  --orange: #e18e29;
  --green: #30dd5e;
  --purple: #ac30dd;
}

*:focus-visible {
  transition: 200ms !important;
  /* box-shadow: 0 0 0 2px var(--p200) !important; */
}

::selection {
  background: var(--p500);
  color: white;
}

a {
  font-size: 14px !important;
  text-decoration: none !important;
}

p {
  font-size: 14px;
}

label {
  font-size: 14px !important;
}

td {
  font-size: 14px;
  border-bottom: none !important;
}

th {
  font-size: 12px !important;
  text-transform: none !important;
  border-bottom: 1px solid var(--divider3) !important;
}

button {
  font-size: 14px !important;
  /* padding: 8px 32px !important; */
}

.btn-solid {
  background: var(--divider) !important;
}

.btn-outline {
  border: 1px solid var(--divider2);
}

.btn-outline:hover:not(:disabled),
.btn-solid:hover:not(:disabled) {
  background: var(--divider2) !important;
}

.btn:hover:not(:disabled) {
  background: var(--divider2) !important;
}

.btn {
  background: transparent !important;
}

.btn:active:not(:disabled),
.btn-solid:active:not(:disabled) {
  background: var(--divider3) !important;
}

.btn-clear {
  background: none !important;
}

.btn-clear:hover {
  background: none !important;
}

.btn-clear:active {
  background: none !important;
}

.clicky:active:not(:disabled) {
  transform: scale(0.95);
}

.sm-clicky:active:not(:disabled) {
  transform: scale(0.9);
}

.lg-clicky:active:not(:disabled) {
  transform: scale(0.97);
}

input,
textarea {
  border-radius: 8px !important;
  outline: none !important;
  border: 1px solid var(--divider3) !important;
  font-size: 14px !important;
  /* backdrop-filter: blur(10px); */
}

input::placeholder,
textarea::placeholder {
  /* opacity: 0.5; */
  color: #96969691;
}

input:focus,
input:focus-visible,
input.filled:focus,
input.filled:focus-visible,
select:focus,
textarea:focus,
textarea:focus-visible,
textarea.filled:focus,
textarea.filled:focus-visible {
  transition: 200ms !important;
  box-shadow: 0 0 0 2px var(--p500) !important;
  /* border: 1px solid var(--p500) !important; */
}

input.filled {
  border: 1px solid var(--divider3) !important;
  background: var(--divider) !important;
}

input.filled:focus,
input.filled:focus-visible {
  background: transparent !important;
}

select {
  border: 1px solid var(--divider3) !important;
  font-size: 14px !important;
}

.chakra-alert {
  width: 80vw !important;
  max-width: 400px !important;
  padding: 16px !important;
}

.chakra-alert__title {
  font-size: 14px;
  line-height: normal !important;
  margin-top: 3px !important;
}

.chakra-alert__desc {
  font-size: 14px;
  line-height: normal !important;
  margin-top: 4px;
  max-width: 450px !important;
}

.alert {
  min-width: 100% !important;
  border-radius: 8px;
}

.css-o807cm[data-active],
.css-1veby0y[data-active],
.css-1nz3jkt[data-active] {
  background: var(--divider3) !important;
}

.chakra-form__error-message {
  font-size: 12px !important;
}

.chakra-form__helper-text {
  font-size: 12px !important;
}

/* .scrollX::-webkit-scrollbar {
  height: 10px;
}

.scrollY::-webkit-scrollbar {
  width: 10px;
}

.scrollX::-webkit-scrollbar-thumb,
.scrollY::-webkit-scrollbar-thumb {
  background-color: var(--divider3);
  border-radius: 8px;
}

.scrollX::-webkit-scrollbar-thumb:hover,
.scrollY::-webkit-scrollbar-thumb:hover {
  background-color: var(--divider2);
}

.scrollX::-webkit-scrollbar-track,
.scrollY::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0 0 8px 8px;
} */

.noScroll::-webkit-scrollbar {
  display: none !important;
}

.breadcrumb {
  width: fit-content;
  gap: 8px;
  background: var(--divider);
  border-radius: 8px;
  padding: 4px 16px;
  height: 30px;
}

.breadcrumbItem,
.breadcrumbSeparator {
  font-size: 11px !important;
}

.breadcrumbCurrent {
  font-weight: 600;
  cursor: default;
}

.accordionItem {
  border-radius: 16px;
  border: 1px solid var(--divider);
  box-shadow: 1px 1px 2px 1px var(--divider);
}

.accordionButton {
  padding: 24px;
  border-radius: 16px;
  justify-content: space-between;
  align-items: flex-start;
}

/* custom */
.poinTitle {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.3;
}

.poinNumber {
  text-align: left;
  width: 48px;
  flex-shrink: 0;
}

.ppText {
  /* opacity: 0.6; */
  text-align: justify;
}

.layananItem {
  justify-content: space-between;
  align-items: flex-start !important;
  padding: 24px;
  border-radius: 8px;
  /* background: var(--p500); */
  height: 300px;
  position: relative;
  border: 1px solid var(--p500a);
  background: var(--p500a);
  overflow: hidden;
  transition: 200ms;
}

/* .layananItem:hover {
  transform: scale(1.03);
  box-shadow: 0 0 0 2px var(--p400);
} */
/* .layananItem:hover .layananItemIcon {
  transform: rotate(-10deg);
} */
/* .layananItem:hover .layananItemIconBg {
  transform: scale(1.1);
} */

.layananItemIcon {
  transition: 200ms;
  width: 50px;
}

.layananItemIconBg {
  padding: 8px;
  border-radius: 8px;
  transition: 200ms;
  color: white;
  /* background: var(--p600); */
}

.layananItemIconOverlay {
  width: 200px;
  opacity: 1;
  position: absolute;
  bottom: -50px;
  right: -50px;
}

.layananItemNarasi {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
}

.gambarGila {
  -webkit-mask: linear-gradient(
    0deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  mask: linear-gradient(0deg, transparent, white 20%, white 80%, transparent);
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 8px;
}

.detailKontenLabel {
  opacity: 0.5;
  width: 140px;
}

.editable {
  cursor: pointer;
  width: max-content;
  position: relative;
}

.editable:hover::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffd91ca6;
  box-shadow: 0 0 0 6px #ffd91ca6;
}

.profileLabel {
  width: 160px;
  flex-shrink: 0;
}

@keyframes gambarGila {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-50%);
  }
}

@keyframes infiniteScrollX {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

/* Media Querries */

@media screen and (min-width: 768px) {
  a {
    font-size: 16px !important;
  }

  p {
    font-size: 16px;
  }

  label {
    font-size: 16px !important;
  }

  td {
    font-size: 16px !important;
  }

  th {
    font-size: 14px !important;
  }

  .breadcrumbItem,
  .breadcrumbSeparator {
    font-size: 13px !important;
  }

  .chakra-alert__title {
    font-size: 16px;
  }

  .chakra-alert__desc {
    font-size: 16px;
  }

  .chakra-form__error-message {
    font-size: 14px !important;
  }

  .chakra-form__helper-text {
    font-size: 14px !important;
  }

  button {
    font-size: 15px !important;
  }

  input,
  textarea {
    font-size: 15px !important;
  }

  select {
    font-size: 15px !important;
  }

  .layananItemNarasi {
    font-size: 18px;
  }
}
